/* eslint-disable @typescript-eslint/no-explicit-any */
import { CountryModel } from "./components/models/YapilyModels/CountryModel";
import { autoinject } from "aurelia-framework";
import { Storage } from "./storage";
import { RecipientModel } from "components/models/RecipientModel";
import { YapilyPaymentAuthRequest } from "components/models/YapilyModels/YapilyPaymentAuthRequests";
import { YapilyPaymentRequestModel } from "components/models/YapilyModels/YapilyPaymentRequestModel";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { User } from "components/models/UserModel";
import { ReminderModel } from "components/models/ReminderModel";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { FriendRequestModel } from "components/models/FriendsRequestModel";
import { FriendModel } from "components/models/FriendModel";
import { YapilyPaymentsDetailsModel } from "components/models/YapilyModels/YapilyPaymentsDetailsModel";
import { YapilyAccountIdentificationModel } from "components/models/YapilyModels/YapilyAccountIdentificationModel";
import { WalletInfo } from "services/WalletManager.service";
import { BitcoinTransactionModel } from "components/models/BitcoinTransactionModel";
import localforage from "localforage";

let instance = null;

@autoinject()
export class SingletonService {
  private newsetPhoneCountry;
  private newCountrytoSafe: string;
  private selectedBank: YapilyBankModel;
  private age: number;

  private email: string;

  private isBlurred: boolean = false;

  private currentAccountIndex: number = 0;
  private currentBankIndex: number = 0;
  private isItCryptoWalletSelected: boolean;
  private currentCryptoWalletIndex: number = 0;
  private referenceStruct: string;
  private referenceComm: string;
  private amount: number;
  private name: string;
  private qrCodeAmount: number;
  private qrCodeReference: string;
  private selectedRecipients: RecipientModel;
  private paymentAuthInfomation: YapilyPaymentRequestModel;
  private accounts: Array<YapilyAccountModel>;
  private myBanks: Array<YapilyBankModel>;
  /*   private accountsInstitution: Array<YapilyInstitutionModel> */
  private bankToAdd: YapilyBankDetailsModel;
  private me: User;
  private rememberInfo: ReminderModel;
  private allRecipients: Array<RecipientModel>;
  private newTxCurrency;

  private transactions: YapilyTransactionModel[];
  private tx_bsv: BitcoinTransactionModel[];
  private selectedTransactionBitcoin: BitcoinTransactionModel;

  private selectedQRCodePayementAccount: YapilyAccountModel;

  private reminder_id: string;
  private contactRecipient: any;

  private testTGet: string = "";

  private selectedTransaction: YapilyTransactionModel;
  private transactionsCache = {};

  private friend_email: string;
  private selectedFriendRequestId: string;

  private selectedContactInfo: FriendModel;

  private selectedPhoneCountry: string;

  private paramsLandingAdd;

  private selectedPaymail;

  private MyFriendIdForBsvPayments: string;

  private paymentsDetailsToGet;

  private iban: string;

  private trans: YapilyTransactionModel[];

  private payerName: string;

  private AddingFriendId;

  private friendName: string;

  private selectedContactBtc;

  private registration;

  private amountBtc: string;
  private booleanForInput: boolean = false;
  private commentBtc: string;

  private amountMakePayment: number;

  private commentMakePayment: string;

  private urlPic: string;

  private selectecBankForPayment;

  private myFavouriteLanguage: string;

  private walletsInfos: WalletInfo;

  private myFriendAddressCreated;

  private isReturnBack;
  private isFromTopUp: boolean;
  private isFromMk: boolean;
  private isComingFromQR;
  private outOfDeviceWallet;
  private inDeviceWallet;

  private MyFriendPaymail: string;

  private WalletToDelete;

  private topUpAmount;

  private topUpConverted;

  constructor(private storage: Storage) {
    if (!instance) {
      this.getFromStorage();
    }
    return instance;
  }

  getFromStorage() {
    this.WalletToDelete = this.storage.retrieve("WalletToDelete");
    this.selectedPaymail = this.storage.retrieve("selectedPaymail");
    this.registration = this.storage.retrieve("registration");

    this.MyFriendIdForBsvPayments = this.storage.retrieve(
      "MyFriendIdForBsvPayments"
    );
    this.outOfDeviceWallet = this.storage.retrieve("outOfDeviceWallet");
    this.inDeviceWallet = this.storage.retrieve("inDeviceWallet");
    this.myFavouriteLanguage = this.storage.retrieve("myFavouriteLanguage");
    this.urlPic = this.storage.retrieve("urlPic");
    this.MyFriendPaymail = this.storage.retrieve("MyFriendPaymail");
    this.friendName = this.storage.retrieve("friendName");
    this.testTGet = this.storage.retrieve("testTGet");
    this.paymentsDetailsToGet = this.storage.retrieve("paymentsDetailsToGet");
    this.paramsLandingAdd = this.storage.retrieve("paramsLandingAdd");
    this.newCountrytoSafe = this.storage.retrieve("newCountrytoSafe");
    this.me = this.storage.retrieve("me");
    this.age = this.storage.retrieve("age");
    this.referenceStruct = this.storage.retrieve("referenceStruct");
    this.referenceComm = this.storage.retrieve("referenceComm");
    this.amountBtc = this.storage.retrieve("amountBtc");
    this.commentBtc = this.storage.retrieve("commentBtc");
    this.amountMakePayment = this.storage.retrieve("amountMakePayment");
    this.commentMakePayment = this.storage.retrieve("commentMakePayment");

    this.email = this.storage.retrieve("email");

    /*  this.amount = this.storage.retrieve('amount'); */
    this.qrCodeAmount = this.storage.retrieve("qrCodeAmount");
    this.selectedRecipients = this.storage.retrieve("selectedRecipients");
    this.paymentAuthInfomation = this.storage.retrieve("paymentAuthInfomation");
    this.selectedBank = this.storage.retrieve("selectedBank");
    this.accounts = this.storage.retrieve("accounts");

    this.currentAccountIndex =
      this.storage.retrieve("current_account_index") || 0;
    this.currentBankIndex = this.storage.retrieve("currentBankIndex") || 0;
    this.isItCryptoWalletSelected =
      this.storage.retrieve("isCryptoWalletSelected") || false;
    this.currentCryptoWalletIndex =
      this.storage.retrieve("currentCryptoWalletIndex") || 0;

    this.bankToAdd = this.storage.retrieve("bankToAdd");
    // this.myBanks = this.storage.retrieve('myBanks')
    this.myBanks = JSON.parse(localStorage.getItem("myBanks"));

    this.transactions = this.storage.retrieve("transactions");
    this.trans = this.storage.retrieve("trans");
    this.allRecipients = this.storage.retrieve("allRecipients");
    this.rememberInfo = this.storage.retrieve("reminders");

    this.payerName = this.storage.retrieve("payerName");

    this.myFriendAddressCreated = this.storage.retrieve(
      "myFriendAddressCreated"
    );

    this.iban = this.storage.retrieve("iban");

    this.selectedContactBtc = this.storage.retrieve("selectedContactBtc");

    this.AddingFriendId = this.storage.retrieve("AddingFriendId");

    this.newTxCurrency = this.storage.retrieve("newTxCurrency");

    this.reminder_id = this.storage.retrieve("reminder_id");
    this.selectedTransaction = this.storage.retrieve("selectedTransaction");

    this.transactionsCache = this.storage.retrieve("transactionsCache") || {};

    this.walletsInfos = this.storage.retrieve("walletsInfos");

    this.selectedFriendRequestId = this.storage.retrieve(
      "selectedFriendRequestId"
    );
    this.contactRecipient = this.storage.retrieve("contactRecipient");
    this.selectedContactInfo = this.storage.retrieve("selectedContactInfo");
    this.selectecBankForPayment = this.storage.retrieve(
      "selectecBankForPayment"
    );

    this.topUpAmount = this.storage.retrieve("topUpAmount");
    this.topUpConverted = this.storage.retrieve("topUpConverted");
  }

  
  public getTopUpAmount() {
    return this.topUpAmount;
  }

  public setTopUpAmount(newTopUpAmount) {
    this.topUpAmount = newTopUpAmount;
    this.setToStorage("topUpAmount", this.topUpAmount);
  }

  public getTopUpConverted() {
    return this.topUpConverted;
  }

  public setTopUpConverted(newTopUpConverted) {
    this.topUpConverted = newTopUpConverted;
    this.setToStorage("topUpConverted", this.topUpConverted);
  }

  public setRegistration(newRegistration) {
    this.registration = newRegistration;
    this.setToStorage("registration", this.registration);
  }

  public getRegistration() {
    return this.registration;
  }

  public setAllCryptoWallets(walletInfo: WalletInfo) {
    this.walletsInfos = walletInfo;
    this.setToStorage("walletsInfos", this.walletsInfos);
  }

  public getAllCryptoWallets(): any {
    return this.walletsInfos;
  }

  public setMyBankInfoForPayment(bankMakePaymentInfo: any) {
    this.selectecBankForPayment = bankMakePaymentInfo;
    this.setToStorage("selectecBankForPayment", this.selectecBankForPayment);
  }

  public getMyBankInfoForPayment(): any {
    return this.selectecBankForPayment;
  }

  public setEmail(email: any) {
    this.email = email;
    this.setToStorage("email", this.email);
  }

  public setProfilePic(urlPic: any) {
    this.urlPic = urlPic;
    this.setToStorage("urlPic", this.urlPic);
  }

  public getProfilePic() {
    return this.urlPic;
  }

  public getEmail(): any {
    return this.email;
  }

  public setContactInfo(contactInfo: FriendModel) {
    this.selectedContactInfo = contactInfo;
    this.setToStorage("selectedContactInfo", this.selectedContactInfo);
  }

  public getContactInfo(): FriendModel {
    return this.selectedContactInfo;
  }

  public setAmountBtc(NewAmountBtc: string) {
    this.amountBtc = NewAmountBtc;
    this.setToStorage("amountBtc", this.amountBtc);
  }

  public getAmountBtc() {
    return this.amountBtc;
  }

  public setCommentBtc(NewCommentBtc: string) {
    this.commentBtc = NewCommentBtc;
    this.setToStorage("commentBtc", this.commentBtc);
  }

  public getCommentBtc() {
    return this.commentBtc;
  }

  public setMyFriendAddressCreated(newFriendAddressCreated) {
    this.myFriendAddressCreated = newFriendAddressCreated;
    this.setToStorage("myFriendAddressCreated", this.myFriendAddressCreated);
  }

  public getMyFriendAddressCreated() {
    return this.myFriendAddressCreated;
  }

  public setMyFriendPaymail(newMyFriendPaymail) {
    this.MyFriendPaymail = newMyFriendPaymail;
    this.setToStorage("myFriendAddressCreated", this.MyFriendPaymail);
  }

  public getMyFriendPaymail() {
    return this.MyFriendPaymail;
  }

  public setMyFavoriteLanguage(newFavoriteLanguage: string) {
    this.myFavouriteLanguage = newFavoriteLanguage;
    this.setToStorage("myFavouriteLanguage", this.myFavouriteLanguage);
  }
  public getMyFavoriteLanguage() {
    return this.myFavouriteLanguage;
  }

  public setIsReturnBack(isReturnBack: boolean) {
    this.isReturnBack = isReturnBack;
    this.setToStorage("isReturnBack", isReturnBack);
  }

  public getIsReturnBack() {
    return this.isReturnBack;
  }

  public setTest(setTest: any) {
    this.testTGet = setTest;
    this.setToStorage("testTGet", this.testTGet);
  }
  public getTest() {
    return this.testTGet;
  }

  public setAmountMakePayment(setAmountMakePayment: any) {
    this.amountMakePayment = setAmountMakePayment;
    this.setToStorage("amountMakePayment", this.amountMakePayment);
  }
  public getAmountMakePayment() {
    return this.amountMakePayment;
  }

  public setCommentMakePayment(setCommentMakePayment: any) {
    this.commentMakePayment = setCommentMakePayment;
    this.setToStorage("commentMakePayment", this.commentMakePayment);
  }
  public getCommentMakePayment() {
    return this.commentMakePayment;
  }

  public setPaymentsDetails(setPaymentsDetails: YapilyPaymentsDetailsModel) {
    this.paymentsDetailsToGet = setPaymentsDetails;
    this.setToStorage("paymentsDetailsToGet", this.paymentsDetailsToGet);
  }
  public getPaymentsDetails(): YapilyPaymentsDetailsModel {
    return this.paymentsDetailsToGet;
  }
  /* set current contact account */
  public setSelectedContactRecipient(contactRecipientToSet: any) {
    this.contactRecipient = contactRecipientToSet;
    this.setToStorage("contactRecipient", this.contactRecipient);
  }
  public getSelectedContactRecipient() {
    return this.contactRecipient;
  }

  public SetSelectContactBtc(selectedContactBitcoinToSet: any) {
    this.selectedContactBtc = selectedContactBitcoinToSet;
    this.setToStorage("selectedContactBtc", this.selectedContactBtc);
  }
  public getSelectedContactBtc() {
    return this.selectedContactBtc;
  }

  public setMyFriendIdForBsvPayments(MyFriendIdForBsvPayments: any) {
    this.MyFriendIdForBsvPayments = MyFriendIdForBsvPayments;
    this.setToStorage(
      "MyFriendIdForBsvPayments",
      this.MyFriendIdForBsvPayments
    );
  }
  public getMyFriendIdForBsvPayments() {
    return this.MyFriendIdForBsvPayments;
  }

  /* public setReceivedAccounts(accountsToSet: Array<YapilyAccountModel>) {
    this.accounts = accountsToSet;
    this.setToStorage("accounts", this.accounts)
  }
  public getReceivedAccounts() {
    return this.accounts
  } */

  /**
   * /friend & /add_account_friend
   * id du compte selectionné quand on accepte une demande d'ami
   */
  public getSelectedFriendRequestId(): string {
    return this.selectedFriendRequestId;
  }

  /**
   * /friend & /add_account_friend
   * id du compte selectionné quand on accepte une demande d'ami
   */
  public setSelectedFriendRequestId(id: string) {
    this.selectedFriendRequestId = id;
    this.setToStorage("friend_email", this.selectedFriendRequestId);
  }

  /**
   * /friend
   * sert à enregister un email entrée
   * pour ensuite lui envoyer une demande d'ami
   * entre le page /friend et /select_account_to_share_to_friend
   */
  setFriendEmail(email: string) {
    this.friend_email = email;
    this.setToStorage("friend_email", this.friend_email);
  }

  getFriendEmail(): string {
    return this.friend_email;
  }

  setFriendName(newFriendName: string) {
    this.friendName = newFriendName;
    this.setToStorage("friendName", this.friendName);
  }

  getFriendName(): string {
    return this.friendName;
  }

  setWalletToDelete(newsetWalletToDelete: string) {
    this.WalletToDelete = newsetWalletToDelete;
    this.setToStorage("setWalletToDelete", this.WalletToDelete);
  }

  getWalletToDelete(): string {
    return this.WalletToDelete;
  }

  /**
   * sert à entregistrer les transactions d'un compte
   * pour pouvoir les recharger plus rapidement au chargement de home
   * on affichera celle qui sont cached pendant qu'on sera entrain de reload en parallèle
   */
  setTransactionsCache(
    accountID: string,
    transactions: YapilyTransactionsModel
  ) {
    this.transactionsCache[accountID] = transactions;
    // console.log(accountID)
    // console.warn("cache register: ", this.transactionsCache)
    this.setToStorage("transactionsCache", this.transactionsCache);
    // console.log(this.transactionsCache)
  }

  /**
   * permet de récuperer les transactions d'un compte donnée depuis le cache
   */
  getTransactionsCacheForAccount(accountID: string): YapilyTransactionsModel {
    // console.log({ accountID })
    // console.warn("cache load:", this.transactionsCache[accountID])
    return this.transactionsCache[accountID];
  }

  /**
   * nécéssaire pour passer la transaction de
   * transactions à transaction-component
   * et aussi de select_trasaction_category à transaction_componentt
   */
  setSelectedTransaction(transaction: YapilyTransactionModel) {
    this.selectedTransaction = transaction;
    this.setToStorage("selectedTransaction", this.selectedTransaction);
  }

  getSelectedTransaction(): YapilyTransactionModel {
    return this.selectedTransaction;
  }

  setSelectedTransactionBitcoin(tx_bsv: BitcoinTransactionModel) {
    this.selectedTransactionBitcoin = tx_bsv;
    this.setToStorage(
      "selectedTransactionBitcoin",
      this.selectedTransactionBitcoin
    );
  }

  getSelectedTransactionBitcoin(): BitcoinTransactionModel {
    return this.selectedTransactionBitcoin;
  }

  setSelectedQRCodePayementAccount(account: YapilyAccountModel) {
    this.selectedQRCodePayementAccount = account;
  }

  getSelectedQRCodePayementAccount(): YapilyAccountModel {
    return this.selectedQRCodePayementAccount;
  }

  setToStorage(varNameToStore, actualContentToStore) {
    this.storage.store(
      varNameToStore,
      actualContentToStore,
      60 * 60 * 24 * 365 * 10,
      false
    );
  }

  async setToLocalDB(varNameToStore, actualContentToStore) {
    await localforage.setItem(varNameToStore, actualContentToStore);
  }

  async getFromLocalDB(varNameToFetch) {
    return await localforage.getItem(varNameToFetch);
  }

  public setCountries(CountrytoSafe: string) {
    this.newCountrytoSafe = CountrytoSafe;
    this.setToStorage("newCountrytoSafe", this.newCountrytoSafe);
  }
  public getCountries() {
    return this.newCountrytoSafe;
  }

  public setTxCurrency(txCurrency: string) {
    this.newTxCurrency = txCurrency;
    this.setToStorage("newTxCurrency", this.newTxCurrency);
  }
  public getTxCurrency() {
    return this.newTxCurrency;
  }

  public setPhoneCountry(setPhoneCountry: string) {
    this.newsetPhoneCountry = setPhoneCountry;
    this.setToStorage("newsetPhoneCountry", this.newsetPhoneCountry);
  }

  public getPhoneCountry() {
    return this.newsetPhoneCountry;
  }

  public setBank(selectedBankToSet: YapilyBankModel) {
    this.selectedBank = selectedBankToSet;
    this.setToStorage("selectedBank", this.selectedBank);
  }
  public getBank() {
    return this.selectedBank;
  }

  public setMyBanks(myBankToSet: Array<YapilyBankModel>) {
    /* console.warn("banks saved", myBankToSet) */
    this.myBanks = myBankToSet;
    localStorage.setItem("myBanks", JSON.stringify(this.myBanks));
  }

  public getMyBanks() {
    /*  console.warn("banks loaded:", this.myBanks) */

    return this.myBanks;
  }

  public setIban(myIbanToSet: string) {
    this.iban = myIbanToSet;
    this.setToStorage("iban", this.iban);
  }

  public getIban() {
    return this.iban;
  }

  public setPayerName(myPayerNameToSet: string) {
    this.payerName = myPayerNameToSet;
    this.setToStorage("payerName", this.payerName);
  }

  public getPayerName() {
    return this.payerName;
  }

  public setBankToAdd(bankToAddToSet: YapilyBankDetailsModel) {
    this.bankToAdd = bankToAddToSet;
    this.setToStorage("bankToAdd", this.bankToAdd);
  }

  public getAddedBank() {
    return this.bankToAdd;
  }

  public setParamsLanding(paramsLandingToSet) {
    this.paramsLandingAdd = paramsLandingToSet;
    this.setToStorage("paramsLandingAdd", this.paramsLandingAdd);
  }

  public getParamsLanding() {
    return this.paramsLandingAdd;
  }

  /* 
    public getCurrentInstitution(institutions: YapilyInstitutionModel[]) {
      this.accountsInstitution = institutions;
      this.setToStorage("institutions", institutions)
    } */

  public setAge(ageToSet: number) {
    this.age = ageToSet;
    this.setToStorage("age", this.age);
  }
  public getAge() {
    return this.age;
  }

  public setMe(meToSet: User) {
    this.me = meToSet;
    this.setToStorage("me", this.me);
  }
  public getMe() {
    return this.me;
  }

  public async setMeDB(meToSet: User) {
    await this.setToLocalDB("me", meToSet);
  }
  public async getMeDB() {
    return await this.getFromLocalDB("me");
  }
  /* setAddingFriendId */

  public setAddingFriendId(AddingFriendIdToSet: string) {
    this.AddingFriendId = AddingFriendIdToSet;
    this.setToStorage("AddingFriendId", this.AddingFriendId);
  }
  public getAddingFriendId() {
    return this.AddingFriendId;
  }

  /*  setAddingFriendId */

  public setReferenceStruct(referenceStructToSet: string) {
    this.referenceStruct = referenceStructToSet;
    this.setToStorage("referenceStruct", this.referenceStruct);
  }
  public getReferenceStruct() {
    return this.referenceStruct;
  }

  public setAmount(amountToSet: number) {
    this.amount = amountToSet;
    this.setToStorage("amount", this.amount);
  }
  public getAmount() {
    return this.amount;
  }

  public setQrCodeReference(qrCodeReferenceToSet: string) {
    this.qrCodeReference = qrCodeReferenceToSet;
    this.setToStorage("QrCodeReference", this.qrCodeReference);
  }

  public getQrCodeReference() {
    return this.qrCodeReference;
  }

  public setQrCodeAmount(qrCodeAmountToSet: number) {
    this.qrCodeAmount = qrCodeAmountToSet;
    this.setToStorage("amount", this.qrCodeAmount);
  }

  public getQrCodeAmount() {
    return this.qrCodeAmount;
  }

  /*BITCOIN QR CODE */
  /* public setBitcoinQrCodeReference(qrCodeReferenceToSet: string) {
    this.qrCodeReference = qrCodeReferenceToSet;
    this.setToStorage("QrCodeReference", this.qrCodeReference);
  }

  public getBitcoinQrCodeReference() {
    return this.qrCodeReference;
  }

  public setBitcoinQrCodeAmount(qrCodeAmountToSet: number) {
    this.qrCodeAmount = qrCodeAmountToSet;
    this.setToStorage("amount", this.qrCodeAmount);
  }

  public getBitcoinQrCodeAmount() {
    return this.qrCodeAmount;
  } */

  public setSelectedRecipients(recipientsToSet: RecipientModel) {
    this.selectedRecipients = recipientsToSet;
    this.setToStorage("selectedRecipients", this.selectedRecipients);
  }

  /**
   * ne retourne qu'un seul récipient malgré le S à la fin
   */
  public getRecipients() {
    return this.selectedRecipients;
  }

  public setSelectedPaymail(payymailToSet: RecipientModel) {
    this.selectedPaymail = payymailToSet;
    this.setToStorage("selectedPaymail", this.selectedPaymail);
  }

  public getSelectedPaymail() {
    return this.selectedPaymail;
  }

  public setSelectedPaymailBoolean(isItPaymail: boolean) {
    this.booleanForInput = isItPaymail;
  }

  public getSelectedPaymailBoolean() {
    return this.booleanForInput;
  }

  /**
   * fonction temporaire pour éviter d'avoir à corrigé tout les appels de la fonction au dessus
   * @return le recipient selectionné pour make_payment
   */
  public getSelectedRecipient(): RecipientModel {
    return this.selectedRecipients;
  }

  public setAllRecipients(allRecipientsToSet: Array<RecipientModel>) {
    this.allRecipients = allRecipientsToSet;
    this.setToStorage("allRecipients", this.allRecipients);
  }
  public getAllRecipients(): Array<RecipientModel> {
    return this.allRecipients;
  }

  public setAllRecipientsUk(allRecipientsToSet: Array<RecipientModel>) {
    this.allRecipients = allRecipientsToSet;
    this.setToStorage("allRecipients", this.allRecipients);
  }
  public getAllRecipientsUk(): Array<RecipientModel> {
    return this.allRecipients;
  }

  public setPaymentAuthInfomation(
    paymentAuthInfomationToSet: YapilyPaymentRequestModel
  ) {
    this.paymentAuthInfomation = paymentAuthInfomationToSet;
    this.setToStorage("paymentAuthInfomation", this.paymentAuthInfomation);
  }
  public getPaymentAuthInfomation() {
    return this.paymentAuthInfomation;
  }

  public setCurrentAccountIndex(currents_accountsToSet: number) {
    this.currentAccountIndex = currents_accountsToSet;
    this.setToStorage("current_account_index", this.currentAccountIndex);
  }
  public getCurrentAccountIndex(): number {
    return this.currentAccountIndex;
  }

  public setCurrentBankIndex(bankIndexToSet: number) {
    this.currentBankIndex = bankIndexToSet;
    this.setToStorage("currentBankIndex", this.currentBankIndex);
  }

  public getCurrentBankIndex(): number {
    return this.currentBankIndex;
  }

  public isCryptoWalletSelected() {
    return this.isItCryptoWalletSelected;
  }

  public setCryptoWalletSelected(isCryptoWalletSelected: boolean) {
    this.isItCryptoWalletSelected = isCryptoWalletSelected;
    this.setToStorage("isCryptoWalletSelected", this.isItCryptoWalletSelected);
  }

  public getCryptoWalletSelected() {
    return this.isItCryptoWalletSelected;
  }

  public getCurrentCryptoWalletIndex() {
    return this.currentCryptoWalletIndex;
  }

  public setCurrentCryptoWalletIndex(currentCryptoWalletIndex: number) {
    this.currentCryptoWalletIndex = currentCryptoWalletIndex;
    this.setToStorage(
      "currentCryptoWalletIndex",
      this.currentCryptoWalletIndex
    );
  }

  public getCurrentBank(): YapilyBankModel {
    return this.myBanks[this.currentBankIndex];
  }

  public getCurrentBankAccount(): YapilyAccountModel {
    // console.log(this.myBanks)
    // console.log("indexes:", this.currentBankIndex, this.currentAccountIndex)
    // console.log(this.myBanks[this.currentBankIndex].accounts[this.currentAccountIndex])
    return this.myBanks[this.currentBankIndex].accounts[
      this.currentAccountIndex
    ];
  }

  public setInDeviceWallet(setIndeviceAccountToSet) {
    this.inDeviceWallet = setIndeviceAccountToSet;
    this.setToStorage("inDeviceWallet", this.inDeviceWallet);
  }

  public getInDeviceWallet() {
    return this.inDeviceWallet;
  }

  public setOutOfDeviceWallet(setOutOfDeviceAccountToSet) {
    this.outOfDeviceWallet = setOutOfDeviceAccountToSet;
    this.setToStorage("outOfDeviceWallet", this.outOfDeviceWallet);
  }

  public getOutOfDeviceWallet() {
    return this.outOfDeviceWallet;
  }

  public setReceivedAccounts(accountsToSet: Array<YapilyAccountModel>) {
    this.accounts = accountsToSet;
    this.setToStorage("accounts", this.accounts);
  }

  public getReceivedAccounts() {
    return this.accounts;
  }

  /*  public setTransactions(transactions: Array<YapilyTransactionModel>) {
    this.transactions = transactions;
    this.setToStorage("transactions", transactions);
  }


  public getTransactions(): Array<YapilyTransactionModel> {
    return this.transactions;
  } */

  public setTrans(trans: Array<YapilyTransactionModel>) {
    this.trans = trans;
    this.setToStorage("trans", trans);
  }

  public getTrans(): Array<YapilyTransactionModel> {
    return this.trans;
  }

  public setRememberInfo(rememberInfo: ReminderModel) {
    this.rememberInfo = rememberInfo;
    this.setToStorage("reminders", this.rememberInfo);
  }

  public getRemember(): ReminderModel {
    return this.rememberInfo;
  }

  /**
   * enregistre l'id de reminder utilisé sur la page make_payement
   * pour pouvoir y avoir accès depuis la page payement_landing
   * pour que le backend puis patch le reminder correspondant
   * quand on est effectue son payement
   */
  public setReminderID(reminder_id: string) {
    this.reminder_id = reminder_id;
    this.setToStorage("reminder_id", reminder_id);
  }

  public getReminderID() {
    return this.reminder_id;
  }

  public clearReminderID() {
    this.reminder_id = undefined;
    this.setToStorage("reminder_id", undefined);
  }

  public setName(name) {
    this.name = name;
    this.setToStorage("name", this.name);
  }

  public getName() {
    return this.name;
  }

  //blur related functions

  public setBlur(blurred) {
    this.isBlurred = blurred;
    this.setToStorage("blur", this.isBlurred);
  }

  public getBlur() {
    if (this.isBlurred == undefined) {
      return (this.isBlurred = false);
    } else {
      //this.isBlurred = localStorage.getItem(blur.data)
      return this.isBlurred;
    }
  }

  // public getAllMyBankAccounts(): Array<YapilyAccountModel> {
  //   const accounts = [];

  //   this.myBanks.forEach((bank) => {
  //     bank.accounts.forEach(account => {
  //       accounts.push(account)
  //     })
  //   });

  //   return accounts;
  // }

  public setIsFromTopUp(fromTopUp: boolean) {
    this.isFromTopUp = fromTopUp;
  }

  public getIsFromTopUp() {
    return this.isFromTopUp;
  }

  public setIsFromMk(fromMk: boolean) {
    this.isFromMk = fromMk;
  }

  public getIsFromMk() {
    return this.isFromMk;
  }
}
